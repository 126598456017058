<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('thesis_and_topic_determination')"
                        :isFilter="false"></Header>
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile :title="$t('thesis_and_project')"
                              :isFilter="false"></HeaderMobile>
            </template>
            <div class="row data-form">
                <div class="col-12">
                    <div class="d-flex justify-content-between">
                        <h6 class="text-uppercase mb-0">{{$t('thesis_and_project')}}</h6>
                        <div class="row">
                            <div class="col-12">
                                <b-form-group>
                                    <b-form-input :placeholder="$t('search')" v-model="searchTxt"></b-form-input>
                                </b-form-group>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-12">
                    <b-tabs content-class="py-5 position-relative" class="mt-5" v-model="tabIndex">
                        <div class="tabs-bottom-line"></div>
                        <b-tab active>
                            <template #title><i class="ri-eye-2-fill top-plus-2 mr-2"></i>{{ $t('show_application') }}</template>
                            <div class="row">
                                <div class="col-12 col-md-12">
                                    <b-table striped hover
                                             :items="applications"
                                             :fields="fields"
                                             :filter="searchTxt"
                                             :filter-function="filterCust">
                                        <template #cell(approval_status)="data">
                                            <span v-if="data.item.approval_status_code == 'approved'" v-b-tooltip.hover.bottom :title="$t('attention_for_thesis_and_project')" class="text-success">
                                                <i class="ri-information-line"></i>
                                                {{data.item.approval_status}}
                                            </span>
                                            <span v-if="data.item.approval_status_code == 'pending'" class="text-warning">{{data.item.approval_status}}</span>
                                            <span v-if="data.item.approval_status_code == 'rejected'" class="text-danger">{{data.item.approval_status}}</span>
                                        </template>
                                    </b-table>
                                </div>
                            </div>
                        </b-tab>
                        <b-tab>
                            <template #title><i class="ri-file-list-line top-plus-2 mr-2"></i>{{ $t('make_application') }}</template>
                            <div class="row">
                                <div class="col-12 col-md-12">
                                    <student-form v-if="tabIndex == 1"></student-form>
                                </div>
                            </div>
                        </b-tab>
                    </b-tabs>
                </div>
            </div>
        </app-layout>
    </div>
</template>
<script>
import AppLayout from "@/layouts/AppLayout";
import Header from "@/layouts/AppLayout/Header";
import HeaderMobile from "@/layouts/AppLayout/HeaderMobile";

import ThesisAndProjectService from "@/services/ThesisAndProjectService";
import StudentForm from "./StudentForm";
import moment from "moment";


export default {
    components: {
        AppLayout,
        Header,
        HeaderMobile,

        StudentForm
    },
    data() {
        return {
            tabIndex: 0,
            applications: [],
            fields : [
                {
                    key: 'thesis_title',
                    label: this.$t('thesis_title')
                },
                {
                    key: 'application_date',
                    label: this.$t('application_date')
                },
                {
                    key: 'approval_status',
                    label: this.$t('approval_status')
                },
                {
                    key: 'step_name',
                    label: this.$t('approval_phase')
                },
                {
                    key: 'approval_status_code',
                    label: this.$t('approval_status'),
                    thClass: 'd-none',
                    tdClass: 'd-none'
                },
                {
                    key: 'last_update_date',
                    label: this.$t('last_process_date')
                }

            ],
            searchTxt: null


        }
    },
    metaInfo() {
        return {
            title: this.$t("thesis_and_project_application")
        }
    },
    methods: {
        getData(){
            ThesisAndProjectService.me().then(response => {
                response.data.data.forEach(item => {
                    this.applications.push({
                        thesis_title: item.title,
                        application_date: moment(item.created_at).format('DD.MM.YYYY'),
                        approval_status: this.approvalStatusText(item.approve_status),
                        step_name: item.step_name,
                        approval_status_code: item.approve_status,
                        last_update_date: moment(item.updated_at).format('DD.MM.YYYY'),
                    })
                })

            })
        },

        approvalStatusText(text){
            if(text == 'approved'){
                return this.$t('approved')
            } else if(text == 'pending'){
                return this.$t('pending')
            } else {
                return this.$t('rejected')
            }
        },

        filterCust(row, filter){
            if (row.thesis_title.toLowerCase().indexOf(filter.toLowerCase()) !== -1) {
                return true;
            } else {
                return false;
            }
        }
    },

    created() {
        this.getData()
    }

};
</script>
